export default interface Environment {
  production: boolean;
  gAnaliticsId: string;
  texts: AppTexts;
  datasetsFetchUrl: string;
  partnersFetchUrl: string;
  servicesFetchUrl: string;
  newsFetchUrl: string;
  supportersFetchUrl: string;
  teamMembersFetchUrl: string;
  partnersAboutFetchUrl: string;
  datasetDetailsFetchUrl: string;
  vacanciesFetchUrl: string;
  projectsFetchUrl: string;
}

export interface AppTexts {
  title: string;
  description: string;
  mainHeadline: string;
  aboutUs: string;
  numRecords: number,
  numDatasets: number,
  numSources: number,
  jds: string

}


export const texts_dpla: AppTexts = {
  title: 'Jewish Heritage and History Hub',
  description:
    'Aggregating Jewish content to DPLA',
  mainHeadline: 'Aggregating Jewish content to DPLA',
  aboutUs:
    'Jewish Heritage and History Hub is an initiative by JHN and CJH',
  numRecords: 45456,
  numDatasets: 13,
  numSources: 9,
  jds: ''
};

export const texts_judaica: AppTexts = {
  title: 'Judaica Europeana',
  description:
    'Data Platform for European Jewish Heritage',
  mainHeadline: 'Data Platform for European Jewish Heritage',
  aboutUs:
    '<b>Judaica Europeana</b>, which ran from 2010 to 2012, was one of Europeana’s first aggregation projects. It aimed to add content from Jewish heritage institutions in Europe, the United States and Israel to Europeana, and in total digitised and uploaded 3.7 million items to the platform. <br><br><b>Judaica Europeana 2.0</b> introduced the aggregation platform for Jewish heritage content. This flexible and innovative platform integrates several open-source components to support a wide variety of incoming data sources and map them to the Europeana Data Model. All the content originally supplied by Judaica Europeana project has been re-ingested and re-published on Europeana.',
  numRecords: 834293,
  numDatasets: 58,
  numSources: 33,
  jds: "Jewish Data Space placeholder"

};