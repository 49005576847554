<div class="navbar">
  <div class="brand">
    <a routerLink="/">
      <img src="assets/img/logos/jhn-white.svg" alt="Jewish Heritage Network" />
    </a>
  </div>
  <nav class="nav-menu">

    <a class="nav-menu__item" routerLinkActive="nav-menu__item_active" routerLink="/services"
      title="Services">Services</a>
    <a class="nav-menu__item" routerLinkActive="nav-menu__item_active" routerLink="/jewish-data-space"
      title="Jewish Data Space">Jewish Data Space</a>
    <a class="nav-menu__item" routerLinkActive="nav-menu__item_active" routerLink="/datasets" title="Data Preview">Data
      Preview</a>
    <a class="nav-menu__item" routerLinkActive="nav-menu__item_active" routerLink="/about">About</a>
    <a class="nav-menu__item" title="Contact us" (click)="navItemClickHandler('footer')">Contact</a>
    <a class="nav-menu__item nav-menu__item_twitter" href="https://twitter.com/JewishHeritageN" target="_blank"
      rel="noopener noreferrer" title="Twitter">
    </a>
  </nav>
</div>